/* App.css */
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #282c34;
  color: white;
  font-family: Arial, sans-serif;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem; /* Adjusted size for better alignment */
  margin: 2rem 0;
  flex-wrap: nowrap; /* No wrapping on large screens */
}

.App-logo {
  width: 80px; /* Adjust the size of the logo as needed */
  height: auto;
  margin-left: 1rem;
}

@media (max-width: 600px) {
  .App-header {
    flex-direction: column; /* Stack elements vertically */
    font-size: 2rem; /* Adjust header size for smaller screens */
    text-align: center;
  }

  .App-logo {
    margin-left: 0;
    margin-top: 1rem;
  }
}

.search-container {
  position: relative;
  width: 80%;
  max-width: 600px;
  margin-top: 1rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.search-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

.suggestion-item {
  padding: 0.5rem;
  cursor: pointer;
}

.suggestion-item:hover {
  background: #f1f1f1;
}

@media (max-width: 600px) {
  .search-container {
    width: 90%;
  }
}
